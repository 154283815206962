<template>
  <div>
    <component :is="$route.params.part" />
  </div>
</template>
<script>

export default {
  components: {
    organisations () { return import('@/components/permissions/organisations/') }
  },
  props: {},
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>
